@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: rgb(0, 19, 32);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 19, 32);
  height: 100%;
  width: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.location-icon {
	background: rgba(81, 98, 255, 0.3);
	border-radius: 50%;
	height: 15px;
	width: 15px;
  	position: absolute;
  	z-index: 1;
  	box-shadow: 0 0 0 0 rgba(81, 98, 255 0.3);
	transform: scale(1);
	animation: pulse 2.5s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(81, 98, 255, 0.2);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(81, 98, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(81, 98, 255, 0);
	}
}